import React from 'react';
import { __ } from '../common/utils/LanguageHelper';
import { ThemeProvider } from 'styled-components';
import { saasTheme } from '../common/theme/saas';
import { GlobalStyle } from '../containers/saas.style';
import SEO from '../components/seo';
import { ResetCSS } from '../common/assets/css/style';
import SignupForm from '../containers/SignupForm';

export default () => {
    return (
        <ThemeProvider theme={saasTheme}>
            <SEO title={__('تسجيل حساب', 'Sign Up')} />
            <ResetCSS />
            <GlobalStyle />
            <SignupForm />
        </ThemeProvider>
    );
};
