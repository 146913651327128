import styled from 'styled-components';
import LoadingOverlay from 'react-loading-overlay';

export const StyledLoader = styled(LoadingOverlay)`
    ._loading_overlay_overlay {
        background: rgb(255 255 255 / 44%);
    }
    ._loading_overlay_content {
        color: #000;
    }
    .MuiLinearProgress-root {
        margin-bottom: 15px;
    }
`;
