import styled from 'styled-components';

const HorizontalTimelineWrapper = styled.ul`
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;

    .li {
        transition: all 200ms ease-in;
    }
    .timestamp {
        margin-bottom: 20px;
        padding: 0 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: 100;
    }
    .status {
        padding: 0 40px;
        display: flex;
        justify-content: center;
        border-top: 2px solid #d6dce0;
        position: relative;
        transition: all 200ms ease-in;
        @media (max-width: 394px) {
            padding: 0 30px;
        }
        h4 {
            font-weight: 600;
            @media (max-width: 394px) {
                font-size: 14px;
            }
        }
        &:before {
            content: '';
            width: 25px;
            height: 25px;
            background-color: white;
            border-radius: 25px;
            border: 1px solid #ddd;
            position: absolute;
            top: -15px;
            left: 42%;
            transition: all 200ms ease-in;
        }
    }
    .li.complete {
        .status {
            border-top: 2px solid #556adc;
            &:before {
                background-color: #556adc;
                border: none;
                transition: all 200ms ease-in;
            }
            h4 {
                color: #556adc;
            }
        }
    }
`;
export default HorizontalTimelineWrapper;
