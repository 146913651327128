import React from 'react';
import {__} from '../../common/utils/LanguageHelper';
import Input from '../../common/components/Input';
import FullButton from '../FullButton';
import HorizontalTimeline from '../HorizontalTimeline';
import {ErrorMessage, Formik} from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Select from '../../common/components/Select';
import OverlayLoader from '../OverlayLoader';
import {navigate, useIntl} from 'gatsby-plugin-intl';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {domainPattern} from '../../common/utils/globals';
import Filter from 'bad-words';
import {trimObjValues} from '../../common/utils/functions';

export default function Step1({
    formValues,
    setServerErrors,
    passData,
    serverErrors,
    prev,
}) {
    const { locale } = useIntl();
    const MySwal = withReactContent(Swal);
    const filter = new Filter();

    const Signup2Schema = Yup.object().shape({
        country_id: Yup.object().required(
            __('ادخل دولتك', 'Enter your Country')
        ),
        company_name: Yup.string()
            .required(__('ادخل اسم شركتك', 'Enter your company name')),
        company_domain: Yup.string()
            .trim()
            .matches(
                domainPattern,
                __('اسم النطاق غير صالح', 'Invalid Domain name')
            )
            .min(
                3,
                __(
                    'اسم النطاق يجب أن يحتوي على الأقل 3 أحرف',
                    'Domain name must be at least 3 characters'
                )
            )
            .max(
                20,
                __(
                    'اسم النطاق يحب ألا يزيد عن 20 حرف',
                    'Domain name has a maximum limit of 20 characters'
                )
            )
            .test(
                'is-contain-bad-words',
                __(
                    'عفوا برجاء اختيار لفظ مناسب حيث لا يسمج للكمات السيئة',
                    'Please choose an appropriate word, because profanity is not allowed'
                ),
                (value) => {
                    if (value && value.match(domainPattern)) {
                        const cleanBadWords = filter.clean(value);
                        return !cleanBadWords.includes('*');
                    } else {
                        return true;
                    }
                }
            )
            .required(__('ادخل اسم النطاق', 'Enter the domain name')),
        employee_number: Yup.object().required(
            __('ادخل عدد الموظفين في شركتك', 'Enter your employee number')
        ),
    });

    const EmployeesCountSelectOptions = [
        {
            value: '50-00',
            label: '50-00',
        },
        {
            value: '100-500',
            label: '100-500',
        },
        {
            value: '300-100',
            label: '300-100',
        },
        {
            value: '1000-500',
            label: '1000-500',
        },
        {
            value: '3000-1000',
            label: '3000-1000',
        },
        {
            value: '1000000-3000',
            label: '1000000-3000',
        },
    ];

    return (
        <>
            <HorizontalTimeline
                steps={[
                    __('المرحلة الأولى', 'step1'),
                    __('المرحلة الثانية', 'step2'),
                ]}
                activeStepIndex={[0, 1]}
            />

            <Formik
                initialValues={{
                    country_id: formValues.country_id,
                    company_name: formValues.company_name,
                    employee_number: formValues.employee_number,
                    company_domain: formValues.company_domain,
                }}
                validationSchema={Signup2Schema}
                onSubmit={async (values, { setSubmitting }) => {
                    let trim_values = trimObjValues(values);
                    trim_values.company_domain = trim_values.company_domain.toLowerCase();

                    const data = {
                        ...formValues,
                        ...trim_values,
                        country_id: trim_values.country_id['value'],
                        employee_number: trim_values.employee_number['value'],
                    };

                    try {
                        const res = await axios.post(
                            `https://api.awasul1.com/v1/account/create?lang=${locale}`,
                            data
                        );
                        if (res.data.status === 'success') {
                            MySwal.fire({
                                position: 'center',
                                icon: 'success',
                                title:
                                    locale === 'ar'
                                        ? 'تم انشاء الحساب بنجاح'
                                        : 'Account successfully created',
                                showConfirmButton: false,
                                timer: 1000,
                            }).then(async () => {
                                setSubmitting(false);

                                localStorage.setItem(
                                    'domain',
                                    trim_values.company_domain
                                );

                                return navigate(`/login`);
                            });
                        }
                    } catch (error) {
                        if (error.response.status === 500) {
                            return navigate('/500');
                        }

                        if (error.response.data.errors) {
                            if (
                                error.response.data.errors.name ||
                                error.response.data.errors.email ||
                                error.response.data.errors.password ||
                                error.response.data.errors.mobile
                            ) {
                                prev();
                            }
                            setServerErrors(error.response.data.errors);
                        }
                        passData(trim_values);
                        setSubmitting(false);
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleSubmit,
                    handleChange,
                    isSubmitting,
                    setValues,
                }) => (
                    <OverlayLoader
                        active={isSubmitting}
                        title={__(
                            'جاري إنشاء منصة خاصة بكم...',
                            'Your platform is being created...'
                        )}
                    >
                        <form onSubmit={handleSubmit}>
                            <Select
                                options={[
                                    {
                                        value: 1,
                                        label: __('السعودية', 'Saudi arabia'),
                                    },
                                ]}
                                labelText={__('الدولة', 'Country')}
                                name="country_id"
                                value={values.country_id}
                                onChange={(value) => {
                                    values.country_id = value;
                                    setValues(values);
                                }}
                                errorMessage={
                                    <>
                                        <ErrorMessage
                                            name="country_id"
                                            component="div"
                                            className="error-message"
                                        />
                                        {serverErrors.country_id && (
                                            <p className="error-message">
                                                {serverErrors.country_id}
                                            </p>
                                        )}
                                    </>
                                }
                                className={`input ${
                                    (serverErrors.country_id ||
                                        errors.country_id) &&
                                    touched.country_id &&
                                    'select-error'
                                }`}
                                placeholder={__('اختر...', 'Select...')}
                            />

                            <Input
                                inputType="text"
                                label={__('اسم الشركة', 'company name')}
                                name="company_name"
                                value={values.company_name}
                                onChange={handleChange}
                                errorMessage={
                                    <>
                                        <ErrorMessage
                                            name="company_name"
                                            component="div"
                                            className="error-message"
                                        />
                                        {serverErrors.company_name && (
                                            <p className="error-message">
                                                {serverErrors.company_name}
                                            </p>
                                        )}
                                    </>
                                }
                                placeholder={__('اسم الشركة', 'company name')}
                                className={`input ${
                                    (serverErrors.company_name ||
                                        errors.company_name) &&
                                    touched.company_name &&
                                    'input-error'
                                }`}
                            />

                            <Select
                                options={EmployeesCountSelectOptions}
                                labelText={__(
                                    'عدد الموظفين',
                                    'employee number'
                                )}
                                name="employee_number"
                                value={values.employee_number}
                                onChange={(value) => {
                                    values.employee_number = value;
                                    setValues(values);
                                }}
                                errorMessage={
                                    <ErrorMessage
                                        name="employee_number"
                                        component="div"
                                        className="error-message"
                                    />
                                }
                                className={`input ${
                                    errors.employee_number &&
                                    touched.employee_number &&
                                    'select-error'
                                }`}
                                placeholder={__('اختر...', 'Select...')}
                            />

                            <Input
                                inputType="text"
                                label={__('اسم نطاق شركتك', 'company domain')}
                                name="company_domain"
                                ltr
                                staticValue="awasul1.com."
                                value={values.company_domain}
                                onChange={handleChange}
                                errorMessage={
                                    <>
                                        <ErrorMessage
                                            name="company_domain"
                                            component="div"
                                            className="error-message"
                                        />
                                        {serverErrors.company_domain && (
                                            <p className="error-message">
                                                {serverErrors.company_domain}
                                            </p>
                                        )}
                                    </>
                                }
                                placeholder="Ex: energize"
                                className={`input ${
                                    (serverErrors.company_domain ||
                                        errors.company_domain) &&
                                    touched.company_domain &&
                                    'input-error'
                                }`}
                            />

                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                }}
                            >
                                <FullButton
                                    onClick={() => {
                                        passData(values);
                                        prev();
                                    }}
                                    disabled={isSubmitting}
                                    content={__('السابق', 'Previous')}
                                    variant="outline"
                                />
                                <FullButton
                                    disabled={isSubmitting}
                                    type="submit"
                                    content={__('تسجيل', 'Login')}
                                />
                            </div>
                        </form>
                    </OverlayLoader>
                )}
            </Formik>
        </>
    );
}
