import React from 'react';
import { StyledLoader } from './OverlayLoader.style';
import LinearProgress from '@material-ui/core/LinearProgress';

const OverlayLoader = ({ children, title, active, ...rest }) => {
    return (
        <StyledLoader
            active={active}
            spinner={<LinearProgress />}
            text={title}
            {...rest}
        >
            {children}
        </StyledLoader>
    );
};

export default OverlayLoader;
