import React from 'react';
import { __ } from '../../common/utils/LanguageHelper';
import Input from '../../common/components/Input';
import FullButton from '../FullButton';
import { Link } from 'gatsby-plugin-intl';
import HorizontalTimeline from '../HorizontalTimeline';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { ar_En_Nums_Pattern } from '../../common/utils/globals';
import { trimObjValues } from '../../common/utils/functions';

export default function Step1({ passData, serverErrors, formValues, next }) {
    const Signup1Schema = Yup.object().shape({
        name: Yup.string()
            .trim()
            .min(
                3,
                __(
                    'الاسم يجب أن يحتوي على الأقل 3 أحرف',
                    'Name must contain at least 3 characters'
                )
            )
            .max(
                50,
                __(
                    'الاسم يجب ألا يزيد عن 50 حرفًا',
                    'Name has a maximum limit of 50 characters'
                )
            )
            .matches(ar_En_Nums_Pattern, __('الاسم غير صالح', 'Invalid Name'))
            .required(__('ادخل اسمك', 'Enter your name')),
        email: Yup.string()
            .email(
                __(
                    'ادخل بريد إلكتروني صالح مثال: you@example.com',
                    'Enter valid email EX: you@example.com'
                )
            )
            .max(
                255,
                __(
                    'البريد إلكتروني يجب ألا يزيد عن 255 حرفًا',
                    'Email has a maximum limit of 255 characters'
                )
            )
            .required(__('ادخل البريد الإلكتروني', 'Enter your email')),
        password: Yup.string()
            .required(__('ادخل كلمة المرور', 'Enter your password'))
            .min(
                8,
                __(
                    'كلمة المرور يجب أن تحتوي كلمة المرور على الأقل 8 أرقام',
                    'Password must contain at least 8 digits'
                )
            )
            .max(
                255,
                __(
                    'كلمة المرور يجب ألا يزيد عن 255 حرفًا',
                    'Password has a maximum limit of 255 characters'
                )
            ),
        mobile: Yup.string()
            .required(__('ادخل رقم الجوال', 'Enter your phone number'))
            .test(
                'len',
                __('ادخل رقم جوال صالح', 'Enter valid phone number'),
                (val) => val && val.toString().length === 13
            ),
    });

    return (
        <>
            <HorizontalTimeline
                steps={[
                    __('المرحلة الأولى', 'step1'),
                    __('المرحلة الثانية', 'step2'),
                ]}
                activeStepIndex={[0]}
            />

            <Formik
                initialValues={{
                    name: formValues.name,
                    email: formValues.email,
                    password: formValues.password,
                    mobile: formValues.mobile,
                }}
                validationSchema={Signup1Schema}
                onSubmit={(values) => {
                    let trim_values = trimObjValues(values);

                    passData(trim_values);
                    next();
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleSubmit,
                    handleChange,
                    setValues,
                }) => (
                    <>
                        <Input
                            inputType="text"
                            label={__('الاسم', 'Name')}
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            errorMessage={
                                <>
                                    <ErrorMessage
                                        name="name"
                                        component="div"
                                        className="error-message"
                                    />
                                    {serverErrors.name && (
                                        <p className="error-message">
                                            {serverErrors.name}
                                        </p>
                                    )}
                                </>
                            }
                            placeholder={__('ما اسمك؟', "What's your name")}
                            className={`input ${
                                (serverErrors.name || errors.name) &&
                                touched.name &&
                                'input-error'
                            }`}
                        />

                        <Input
                            inputType="email"
                            label={__('البريد الإلكتروني', 'Email Address')}
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            placeholder="you@example.com"
                            errorMessage={
                                <>
                                    <ErrorMessage
                                        name="email"
                                        component="div"
                                        className="error-message"
                                    />
                                    {serverErrors.email && (
                                        <p className="error-message">
                                            {serverErrors.email}
                                        </p>
                                    )}
                                </>
                            }
                            className={`input ${
                                (serverErrors.email || errors.email) &&
                                touched.email &&
                                'input-error'
                            }`}
                        />

                        <Input
                            inputType="password"
                            label={__('كلمة المرور', 'Password')}
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            placeholder={__(
                                'ادخل كلمة المرور',
                                "What's your password?"
                            )}
                            errorMessage={
                                <>
                                    <ErrorMessage
                                        name="password"
                                        component="div"
                                        className="error-message"
                                    />
                                    {serverErrors.password && (
                                        <p className="error-message">
                                            {serverErrors.password}
                                        </p>
                                    )}
                                </>
                            }
                            className={`input ${
                                (serverErrors.password || errors.password) &&
                                touched.password &&
                                'input-error'
                            }`}
                        />

                        <Input
                            inputType="phone"
                            country="SA"
                            international
                            withCountryCallingCode
                            onChange={(val) => {
                                values.mobile = val;
                                setValues(values);
                            }}
                            value={values.mobile}
                            label={__('رقم الجوال', 'phone number')}
                            name="mobile"
                            errorMessage={
                                <>
                                    <ErrorMessage
                                        name="mobile"
                                        component="div"
                                        className="error-message"
                                    />
                                    {serverErrors.mobile && (
                                        <p className="error-message">
                                            {serverErrors.mobile}
                                        </p>
                                    )}
                                </>
                            }
                            placeholder={__('رقم الجوال', 'phone number')}
                            className={`input ${
                                (serverErrors.mobile || errors.mobile) &&
                                touched.mobile &&
                                'input-error'
                            }`}
                        />

                        <FullButton
                            content={__('التالي', 'Next')}
                            onClick={() => {
                                handleSubmit();
                            }}
                        />
                    </>
                )}
            </Formik>

            <h4 className="toggleLink">
                {__(
                    <>
                        هل لديك بالفعل حساب؟{' '}
                        <Link to="/login">تسجيل الدخول</Link>
                    </>,
                    <>
                        Don't have an account? <Link to="/login">Sign Up</Link>
                    </>
                )}
            </h4>
        </>
    );
}
