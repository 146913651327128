import React from 'react';
import HorizontalTimelineWrapper from './HorizontalTimeline.style';
import { useIntl } from 'gatsby-plugin-intl';

export default function HorizontalTimeline({ steps, activeStepIndex }) {
    const { locale } = useIntl();

    return (
        <HorizontalTimelineWrapper lang={locale}>
            {steps.map((step, i) => (
                <li
                    className={`li ${
                        activeStepIndex.includes(i) && 'complete'
                    }`}
                    key={`step-${i + 1}`}
                >
                    <div className="status">
                        <h4>{step}</h4>
                    </div>
                </li>
            ))}
        </HorizontalTimelineWrapper>
    );
}
