import React, { Component } from 'react';
import { Steps, Step } from 'react-step-builder';
import FormContainer from '../FormContainer';
import Step1 from './Step1';
import Step2 from './Step2';
import { LoginProvider } from '../../common/contexts/LoginContext';

class SignupForm extends Component {
    state = {
        form: {
            name: '',
            email: '',
            password: '',
            mobile: '',
            country_id: '',
            company_name: '',
            employee_number: '',
            company_domain: '',
            job_number: '',
        },
        serverErrors: {},
    };

    handleCallback = (formData) => {
        this.setState((prevState) => ({
            form: { ...prevState.form, ...formData },
        }));
    };

    setServerErrors = (errors) => {
        this.setState({
            serverErrors: errors,
        });
    };

    render() {
        return (
            <LoginProvider>
                <FormContainer>
                    <Steps>
                        <Step
                            component={Step1}
                            formValues={this.state.form}
                            serverErrors={this.state.serverErrors}
                            passData={this.handleCallback}
                        />
                        <Step
                            component={Step2}
                            formValues={this.state.form}
                            serverErrors={this.state.serverErrors}
                            setServerErrors={this.setServerErrors}
                            passData={this.handleCallback}
                        />
                    </Steps>
                </FormContainer>
            </LoginProvider>
        );
    }
}

export default SignupForm;
